import { Role } from 'models/role';
import {
  AUTH_TOKEN,
  EXPIRATION_TOKEN,
  PRINCIPAL,
  USER_EMAIL,
  USER_ID,
  USER_ROLE,
} from 'redux/constants/Auth';

let userRole = localStorage.getItem(USER_ROLE);

export function updateUserRole() {
  userRole = localStorage.getItem(USER_ROLE);
}

export function isRole(role) {
  updateUserRole();
  return role === Role[userRole];
}

export function hasRole(...expectedRoles) {
  updateUserRole();
  return expectedRoles.includes(Role[userRole]);
}

export function removePrincipal() {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(EXPIRATION_TOKEN);
  localStorage.removeItem(USER_ROLE);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(PRINCIPAL);
}

export function tokenIsExpired() {
  const token = localStorage.getItem(AUTH_TOKEN);
  const tokenExpiration = localStorage.getItem(EXPIRATION_TOKEN);

  if (!token || !tokenExpiration) {
    return false;
  }

  const currentTime = Date.now();

  if (currentTime > new Date(tokenExpiration)) {
    return true;
  }

  return false;
}

export function getPrincipal() {
  const principal = localStorage.getItem(PRINCIPAL);

  if (principal) {
    return JSON.parse(principal);
  } else {
    return null;
  }
}

export const formatCPF = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};
