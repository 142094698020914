import {
  ContainerOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  PlusSquareOutlined,
  SettingOutlined,
  UserOutlined,
  LinkOutlined,
} from '@ant-design/icons';

import { BiNotepad } from 'react-icons/bi';

import { APP_PREFIX_PATH } from 'configs/AppConfig';

const navigationConfig = [
  {
    key: 'dashboard',
    path: ``,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-default',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'home',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },

  {
    key: 'operations',
    path: ``,
    title: 'sidenav.operations',
    icon: BiNotepad,
    breadcrumb: true,
    submenu: [
      {
        key: 'propostas',
        path: `${APP_PREFIX_PATH}/apps/proposals/list`,
        title: 'sidenav.proposals',
        icon: ContainerOutlined,
        submenu: [],
      },
      {
        key: 'clientes',
        path: `${APP_PREFIX_PATH}/apps/customers/list`,
        title: 'sidenav.clients',
        icon: UserOutlined,
        breadcrumb: false,
        restricted: [
          'ROLE_SUPPORT',
          'ROLE_ADMINISTRATOR',
          'ROLE_SUPERVISOR',
          'ROLE_INTERMEDIATE_BROKER',
        ],
        submenu: [],
      },
      {
        key: 'operations-config',
        path: ``,
        title: 'sidenav.configurations',
        icon: SettingOutlined,
        breadcrumb: true,
        restricted: [
          'ROLE_SUPPORT',
          'ROLE_ADMINISTRATOR',
          'ROLE_SUPERVISOR',
          'ROLE_BROKER',
        ],
        submenu: [
          {
            key: 'benefits',
            path: `${APP_PREFIX_PATH}/apps/benefits-feature/list`,
            title: 'sidenav.configurations.benefits',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'agreements',
            path: `${APP_PREFIX_PATH}/apps/agreements-feature/list`,
            title: 'sidenav.configurations.agreements',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'operations',
            path: `${APP_PREFIX_PATH}/apps/operations-feature/list`,
            title: 'sidenav.configurations.operations',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'bank',
            path: `${APP_PREFIX_PATH}/apps/bank/list`,
            title: 'sidenav.configurations.banks',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'liberationFont',
            path: `${APP_PREFIX_PATH}/apps/liberation-font/list`,
            title: 'sidenav.configurations.releaseResources',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
  {
    key: 'externalOperations',
    path: ``,
    title: 'sidenav.externalOperations',
    icon: BiNotepad,
    breadcrumb: true,
    submenu: [
      {
        key: 'crefaz',
        path: `${APP_PREFIX_PATH}/apps/conta-de-luz/list`,
        title: 'sidenav.externalOperations.crefaz',
        icon: ContainerOutlined,
        submenu: [],
      },
      {
        key: 'icred',
        path: `${APP_PREFIX_PATH}/apps/icred/list`,
        title: 'sidenav.externalOperations.icred',
        icon: ContainerOutlined,
        submenu: [],
      },
    ],
  },

  {
    key: 'app-admin',
    path: ``,
    title: 'sidenav.administration',
    icon: SettingOutlined,
    restricted: [
      'ROLE_SUPPORT',
      'ROLE_ADMINISTRATOR',
      'ROLE_SUPERVISOR',
      'ROLE_BROKER',
    ],
    breadcrumb: false,
    submenu: [
      {
        key: 'usuarios',
        path: `${APP_PREFIX_PATH}/apps/users/list`,
        title: 'sidenav.administration.users',
        icon: UserOutlined,
        breadcrumb: false,
        restricted: ['ROLE_SUPPORT', 'ROLE_ADMINISTRATOR', 'ROLE_SUPERVISOR'],
        submenu: [],
      },
      {
        key: 'useful-links',
        path: `${APP_PREFIX_PATH}/apps/links/manage/`,
        title: 'sidenav.administration.links',
        icon: LinkOutlined,
        breadcrumb: false,
        needSpace: true,
        submenu: [],
      },
    ],
  },
];

export default navigationConfig;
